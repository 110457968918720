import React from 'react'
import { AiTwotoneShop, AiOutlineRight, AiFillCrown } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { setSearchTitle } from '@reducers/contentReducer'
import { useNavigate } from 'react-router-dom'

export default function FindTireByBrand({
    setTireTypeOpen,
    setTireBrandOpen
}) {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const productsFilter = useSelector((state) => state.content.productsFilter)

    return (

        <div className='tire-search'>
            <div className='tire-search-content'>
                <h3>Search by size</h3>
                <div>
                    <input
                        type="text"
                        className="commom-input"
                        value={productsFilter.title}
                        placeholder="Ex: 225/65R17 or 2256017"
                        onChange={(e) => dispatch(setSearchTitle(e.target.value))}
                        onKeyDown={(k) => {
                            if (k.key === "Enter") {
                                navigate('/tires/list')
                            }
                        }}
                    />
                </div>
                <h3>Shop tires by</h3>
                <div className='tire-search-item' onClick={() => {
                    setTireTypeOpen(true)
                }}>
                    <div className='tire-search-item-left'>
                        <AiTwotoneShop size={24} /> Tire types
                    </div>
                    <AiOutlineRight className='tire-search-item-right' />
                </div>
                <div className='tire-search-item' onClick={() => {
                    setTireBrandOpen(true)
                }}>
                    <div className='tire-search-item-left'>
                        <AiFillCrown size={24} /> Tire brands
                    </div>
                    <AiOutlineRight className='tire-search-item-right' />
                </div>
            </div>
        </div>
    )
}
