import React, { useState } from 'react'
// import { AiTwotoneShop, AiOutlineRight, AiFillCrown } from 'react-icons/ai'
// import { useDispatch } from 'react-redux'
// import { setProductsFilter } from '@reducers/contentReducer'
// import { useNavigate } from 'react-router-dom'
// import SearchData from '@data/SearchData'
import './FindTire.css'
// import BrandsData from '@data/BrandsData'
import FindTireBySize from './FindTireBySize'
import FindTireByBrand from './FindTireByBrand'

export default function FindTire({
    setTireTypeOpen,
    setTireBrandOpen
}) {

    // const navigate = useNavigate()
    // const dispatch = useDispatch()
    // const [formData, setFormData] = useState({
    //     width: 'TIRE WIDTH',
    //     profile: 'ASPECT RATIO',
    //     rim: 'RIM SIZE',
    //     type: 'ALL TIRES',
    //     // brand: 'ALL BRANDS'
    // })
    const [type, setType] = useState('size')

    return (
        <div className='tire-search-wrapper'>
            <div className='tire-search-tab'>
                <div className={`tire-search-title ${type === 'size' && 'tire-search-title-active'}`} onClick={() => {
                    setType('size')
                }}>Find by size</div>
                <div className={`tire-search-title ${type === 'brand' && 'tire-search-title-active'}`} onClick={() => {
                    setType('brand')
                }}>Find by brand</div>
            </div>
            {type === 'size' &&
                <FindTireBySize />
            }
            {type === 'brand' &&
                <FindTireByBrand
                    setTireTypeOpen={setTireTypeOpen}
                    setTireBrandOpen={setTireBrandOpen}
                />
            }
        </div>
    )
}
