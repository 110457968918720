import { config } from '../Config'
import axios from 'axios'

const httpClient = axios.create({
    baseURL: config.appBaseUrl,
    timeout: 5 * 1e3,
})

export async function getContent(code) {
    try {
        const res = await httpClient.get(`/content/${code}`)
        // console.log('getContent', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getContentList() {
    try {
        const res = await httpClient.get(`/content`)
        // console.log('getContent', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function postMessage(formData) {
    try {
        const res = await httpClient.post(`/message`, formData)
        // console.log('postMessage', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function register(formData) {
    try {
        const res = await httpClient.post(`/register`, formData)
        // console.log('register', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function login(formData) {
    try {
        const res = await httpClient.post(`/login`, formData)
        // console.log('login', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function changePassword(formData) {
    try {
        const res = await httpClient.post(`/changePassword`, formData)
        // console.log('login', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function tokenlogin(userinfo) {
    try {
        const res = await httpClient.post(`/tokenlogin`, userinfo)
        // console.log('tokenlogin', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function logout() {
    try {
        const res = await httpClient.post(`/logout`)
        // console.log('logout', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getNewList(params) {
    try {
        const res = await httpClient.get(`/new`, { params })
        // console.log('getNewList', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getNewContent(id, params) {
    try {
        const res = await httpClient.get(`/new/${id}`, { params })
        // console.log('getNewContent', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getProductList(params) {
    try {
        const res = await httpClient.get(`/product`, { params })
        // console.log('getProductList', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getProductContent(id, params) {
    try {
        const res = await httpClient.get(`/product/${id}`, { params })
        // console.log('getProductContent', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getProductByTitle(title, params) {
    try {
        const res = await httpClient.get(`/product/title/${title}`, { params })
        // console.log('getProductContent', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getProductTypeList(params) {
    try {
        const res = await httpClient.get(`/productTypeList`, { params })
        // console.log('productTypeList', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getNewTypeList(params) {
    try {
        const res = await httpClient.get(`/newTypeList`, { params })
        // console.log('productTypeList', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getQuestionList(params) {
    try {
        const res = await httpClient.get(`/question`, { params })
        // console.log('productTypeList', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getTopProduct(params) {
    try {
        const res = await httpClient.get(`/getTopProduct`, { params })
        // console.log('productTypeList', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getTopNew(params) {
    try {
        const res = await httpClient.get(`/getTopNew`, { params })
        // console.log('productTypeList', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getGoogleReviews(params) {
    try {
        const res = await httpClient.get(`/getGoogleReviews`, { params })
        // ', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function addView(params) {
    try {
        const res = await httpClient.post(`/addView`, params)
        // console.log('logout', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getShoppingCart() {
    try {
        let tmp_id = localStorage.getItem('tmp_id')
        let userinfo = localStorage.getItem('userinfo')
        userinfo = userinfo ? JSON.parse(userinfo) : null
        // if (!tmp_id && !userinfo) {
        //     tmp_id = uuidv4()
        //     localStorage.setItem('tmp_id', tmp_id)
        // }
        const params = {
            tmp_id,
            userinfo: userinfo?.username,
            token: userinfo?.token
        }
        const res = await httpClient.get(`/shoppingCart`, { params })
        // ', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}

// module.exports = {
//     getContent,
//     postMessage
// }