import './ProductSide.css'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import BrandsData from '@data/BrandsData'
import { useSelector, useDispatch } from 'react-redux'
import { initProductsFilter, setSearchTitle, setStatusType, setFilterTypes, setFilterBrands, setProductsFilterSize } from '@reducers/contentReducer'
import { StatusTypeCode } from '@data/ProductCode'
import SearchData from '@data/SearchData'
import { Tabs } from 'antd'

// let timeout = null

export default function ProductSide() {
    const navigate = useNavigate()
    const productsFilter = useSelector((state) => state.content.productsFilter)
    const [input, setInput] = React.useState(productsFilter?.title)
    const productsTypeList = useSelector((state) => state.content.productsTypeList.filter(item => item.is_wheel === 0))

    useEffect(() => {
        setInput(productsFilter?.title)
    }, [productsFilter.title])

    const dispatch = useDispatch()

    const onClickTypes = (e) => {
        const value = e.target.value
        if (value === 'all') {
            dispatch(setFilterTypes({ type: [], action: 'all' }))
            navigate(`/tires/list`)
            return
        }
        dispatch(setFilterTypes({ type: Number(value), action: e.target.checked ? 'add' : 'remove' }))
    }

    const onClickBrands = (e) => {
        const value = e.target.value
        if (value === 'all') {
            dispatch(setFilterBrands({ brand: [], action: 'all' }))
            return
        }
        dispatch(setFilterBrands({ brand: value, action: e.target.checked ? 'add' : 'remove' }))
    }

    const onClickStatusType = (value) => {
        dispatch(setStatusType(value))
    }

    const brandList = BrandsData.brands
    // brandList.sort(function (a, b) {
    //     if (a.title < b.title) { return -1 }
    //     if (a.title > b.title) { return 1 }
    //     return 0
    // })

    function doSearch(e) {
        // var searchText = e.target.value // this is the search text
        // setInput(searchText)
        // if (timeout) clearTimeout(timeout)
        // timeout = setTimeout(() => {
        //     //search function
        //     dispatch(setSearchTitle(searchText))
        // }, 500)
        dispatch(setSearchTitle(input))
    }

    const [formData, setFormData] = useState({
        width: 'TIRE WIDTH',
        profile: 'ASPECT RATIO',
        rim: 'RIM SIZE',
        // type: 'ALL TIRES',
        // brand: 'ALL BRANDS'
    })

    // get Data
    const getData = (key) => {
        return formData.hasOwnProperty(key) ? formData[key] : ''
    }

    // Set data
    const setData = (key, value) => {
        return setFormData({ ...formData, [key]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log('formData', formData)
        let width = getData('width')
        let profile = getData('profile')
        let rim = getData('rim')
        dispatch(setProductsFilterSize({
            width: width !== 'TIRE WIDTH' ? Number(width) : 0,
            profile: profile !== 'ASPECT RATIO' ? Number(profile) : 0,
            rim: rim !== 'RIM SIZE' ? Number(rim) : 0,
        }))
    }

    function onRestSize() {
        setFormData({
            width: 'TIRE WIDTH',
            profile: 'ASPECT RATIO',
            rim: 'RIM SIZE',
        })
    }

    function onRestKeywords() {
        setInput('')
        dispatch(setSearchTitle(''))
    }

    const items = [
        {
            key: '1',
            label: <h4>Search by size</h4>,
            children: <div className="filter-item">
                {/* <h4>Search by size</h4> */}
                <form onSubmit={handleSubmit}>
                    <div className='tire-search-list tire-search-list-side'>
                        <div className='tire-search-list-item'>
                            <h4>WIDTH</h4>
                            <select value={getData('width')} onChange={(e) => setData('width', e.target.value)}>
                                {SearchData.width.map((item, index) => {
                                    return <option key={index} value={item}>{item}</option>
                                })}
                            </select>
                        </div>
                        <div className='tire-search-list-item'>
                            <h4>PROFILE</h4>
                            <select value={getData('profile')} onChange={(e) => setData('profile', e.target.value)}>
                                {SearchData.profile.map((item, index) => {
                                    return <option key={index} value={item}>{item}</option>
                                })}
                            </select>
                        </div>
                        <div className='tire-search-list-item'>
                            <h4>RIM</h4>
                            <select value={getData('rim')} onChange={(e) => setData('rim', e.target.value)}>
                                {SearchData.rim.map((item, index) => {
                                    return <option key={index} value={item}>{item}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className='tire-search-btns' style={{
                        marginTop: '10px'
                    }}>
                        <button type='submit' className='common-button tire-search-button'>SEARCH</button>
                        <button type='rest' className='common-button tire-search-button-clear' onClick={onRestSize}>CLEAR</button>
                    </div>
                </form>
            </div>
        },
        {
            key: '2',
            label: <h4>Search by keywords</h4>,
            children: <div className="filter-item">
                {/* <h4>Search by keywords</h4> */}
                <input type="text"
                    className="commom-input"
                    value={input}
                    placeholder="Ex: 225/65R17 or 2256017"
                    onChange={(e) => {
                        setInput(e.target.value)
                    }} />
                <div className='tire-search-btns'>
                    <button className='common-button tire-search-button' onClick={doSearch}>SEARCH</button>
                    <button className='common-button tire-search-button-clear' onClick={onRestKeywords}>CLEAR</button>
                </div>
            </div>
        },
    ]

    const onChange = (key) => {
        console.log(key)
    }

    return (

        <section className="product-side">
            <h3>
                Filter
                <Link to='/tires/list' onClick={() => {
                    dispatch(initProductsFilter())
                    onRestSize()
                }}>
                    <button className="common-select">Clear All</button>
                </Link>
            </h3>

            <Tabs defaultActiveKey="1" items={items} onChange={onChange} size='large' />

            <div className="filter-item">
                <h4>Status
                    <span>
                        <label>All <input type="checkbox" value="all" className="input-radio-checkbox"
                            checked={productsFilter.status_type === StatusTypeCode.DEFAULT}
                            onChange={() => onClickStatusType(StatusTypeCode.DEFAULT)} /></label>
                    </span>
                </h4>
                <div className="filter-types">
                    <div className="type-title">
                        <label>
                            <input type="radio" value="1" className="input-radio-checkbox"
                                checked={productsFilter.status_type === StatusTypeCode.ON_SALE}
                                onChange={() => onClickStatusType(StatusTypeCode.ON_SALE)} /> On Sale
                        </label>
                    </div>
                    <div className="type-title">
                        <label>
                            <input type="radio" value="2" className="input-radio-checkbox"
                                checked={productsFilter.status_type === StatusTypeCode.ON_CLEARANCE}
                                onChange={() => onClickStatusType(StatusTypeCode.ON_CLEARANCE)} /> On Clearance
                        </label>
                    </div>
                    <div className="type-title">
                        <label>
                            <input type="radio" value="3" className="input-radio-checkbox"
                                checked={productsFilter.status_type === StatusTypeCode.PREMIUM}
                                onChange={() => onClickStatusType(StatusTypeCode.PREMIUM)} /> Premium
                        </label>
                    </div>
                </div>
            </div>
            <div className="filter-item">
                <h4>Types
                    <span>
                        <label>All <input type="checkbox" value="all" className="input-radio-checkbox"
                            checked={productsFilter.types?.length === 0}
                            onChange={onClickTypes} /></label>
                    </span>
                </h4>
                <div className="filter-types">
                    {productsTypeList && productsTypeList.map((item, key) => (
                        <div key={key} className="type-title">
                            <label>
                                <input type="checkbox" value={item.id} className="input-radio-checkbox"
                                    checked={productsFilter.types?.includes(item.id)}
                                    onChange={onClickTypes} /> {item.title} ({item.count})
                            </label>
                        </div>
                    ))}
                </div>
            </div>
            <div className="filter-item" style={{
                borderBottom: 'none'
            }}>
                <h4>Brands
                    <span>
                        <label>All <input type="checkbox" value="all" className="input-radio-checkbox"
                            checked={productsFilter.brands.length === 0}
                            onChange={onClickBrands} /></label>
                    </span>
                </h4>
                <div className="filter-types">
                    {brandList.map((item, key) => (
                        <div key={key} className="type-title">
                            <label>
                                <input type="checkbox"
                                    checked={productsFilter.brands.includes(item.title)}
                                    onChange={onClickBrands}
                                    value={item.title}
                                    className="input-radio-checkbox" /> {item.title}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
