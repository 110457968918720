import './BannerSection.css'
import React from 'react'

export default function BannerSection({
    color, title, description, background
}) {
    const styles = {
        backgroundImage: `url('${background}')`,
        color: color,
        textAlign: 'center'
    }
    return (
        <section className="banner-wrapper">
            <div className="banner-section" style={styles}>
                <h3>{title}</h3>
                {description && <h4>{description}</h4>}
            </div>
        </section>
    )
}
