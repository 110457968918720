import React, { useState } from 'react'
import ProductView from './components/ProductView'
import PolicyModal from './components/PolicyModal'
import './Product.css'
import { useQuery } from '@tanstack/react-query'
import { getProductContent } from '@services/api.service'
import { useParams } from 'react-router-dom'
import SeoTitleAndDesc from '@components/SeoTitleAndDesc'
import Navigation from '@components/Navigation'
import { config } from '@config'
import { ContentCode } from '@data/ContentData'
import ContentDataHook from '@hooks/ContentData.hook'

export default function ProductDetail({
    // policy,
    // delivery,
    user,
    // currentRow,
    productsTypeList,
    pathname
}) {
    const [show, setShow] = useState(false)
    const { id } = useParams()

    const { data: policy } = ContentDataHook(ContentCode.STORE)
    const { data: delivery } = ContentDataHook(ContentCode.DELIVERY)

    const {
        isPending,
        error,
        data,
    } = useQuery({
        queryKey: ['product', id],
        queryFn: () => getProductContent(id, { username: user?.username || '', token: user?.token || '', pathname: pathname }),
    })

    if (isPending) return <div style={{ textAlign: 'center' }}>Loading...</div>
    if (error) return 'An error has occurred: ' + error.message

    const product = data?.data
    console.log('product', product)

    const typeContent = productsTypeList?.find(item => item.id === product?.type_id)
    return (
        <>
            <SeoTitleAndDesc
                title={`${product?.title} | ${config.appName}`}
                seo_title={product?.seo_title}
                seo_desc={product?.seo_desc}
            />
            <Navigation
                title='Tires'
                titleLink='/tires'
                subtitle={product?.title}
            />
            <PolicyModal
                show={show}
                setShow={setShow}
                policy={policy}
            />
            <ProductView
                user={user}
                currentRow={product}
                setShow={setShow}
            />
            {typeContent && (
                <div className='delivery-item'>
                    <p className='desc-content ql-editor' dangerouslySetInnerHTML={{ __html: typeContent?.description }}></p>
                </div>
            )}
            {delivery && (
                <div className='delivery-item'>
                    <p className='desc-content ql-editor' dangerouslySetInnerHTML={{ __html: delivery.content }}></p>
                </div>
            )}
        </>
    )
}
