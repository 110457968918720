const NavMenuItems = [
    {
        key: '3',
        label: 'Tires',
        link: '/tires/list',
        children: []
    },
    {
        key: '10',
        label: 'Wheels',
        link: '/wheels',
        children: []
    },
    {
        key: '2',
        label: 'Articles',
        link: '/articles',
        children: [],
    },
    {
        key: '2',
        label: 'Policies',
        link: '/policy',
        children: [
            { key: '1', label: 'Refund Policy', link: '/policy/refund' },
            { key: '2', label: 'Warranty Policy', link: '/policy/store' },
            { key: '3', label: 'Privacy Policy', link: '/policy/privacy' },
            { key: '4', label: 'Exclusive Pricing Policy', link: '/policy/pricing' }
        ],
    },
    {
        key: '3',
        label: 'Services',
        link: '/services',
        // children: [
        //     { key: '1', label: 'Tire Installation', link: '/services/tire-installation' },
        //     { key: '2', label: 'Rims and Wheels', link: '/services/rims-and-wheels' },
        //     { key: '3', label: 'Financing', link: '/services/financing' },
        //     { key: '4', label: 'Local Shipping', link: '/services/local-shipping' },
        //     { key: '5', label: 'Shipment to Edmonton & RedDeer', link: '/services/shipping' },
        //     { key: '6', label: 'Alberta Delivery Service Area', link: '/services/shipping-area' },
        // ]
        children: [
            { key: '1', label: 'Tire change', link: '/services/tire-change' },
            { key: '2', label: 'Tire balancing', link: '/services/tire-balancing' },
            { key: '3', label: 'Flat tire repair', link: '/services/flat-tire-repair' },
            { key: '4', label: 'Tire rotation', link: '/services/tire-rotation' },
            { key: '5', label: 'Wheel alignment', link: '/services/wheel-alignment' },
            { key: '6', label: 'Oil change', link: '/services/oil-change' },
            { key: '7', label: 'Financing', link: '/services/financing' },
            { key: '8', label: 'Shipping', link: '/services/shipping' },
            { key: '9', label: 'Rims', link: '/services/tire-rims' },
            { key: '10', label: 'Shipment to Edmonton & RedDeer', link: '/services/shipping' },
            { key: '11', label: 'Alberta Delivery Service Area', link: '/services/shipping-area' },
        ]
    },
    {
        key: '3',
        label: 'FAQ',
        link: '/faq',
        children: [
            { key: '1', label: 'What does M&S stand for?', link: '/faq' },
            { key: '2', label: `Where can I find my vehicle's tire size listed?`, link: '/faq' },
            { key: '3', label: `What could cause a tire to leak?`, link: '/faq' },
            { key: '4', label: `More questions`, link: '/faq' }
        ],
    },
    {
        key: '3',
        label: 'About',
        link: '/about',
        children: [
            { key: '1', label: 'About Us', link: '/about' },
            { key: '2', label: 'Partner Brands', link: '/partner-brands' },
            { key: '3', label: 'Contact', link: '/contact' }
        ],
    },
]

export default NavMenuItems