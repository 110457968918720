import React from 'react'
import './CommentSection.css'
import { config } from '@config'
import { AiFillStar } from 'react-icons/ai'

const comments = [
    {
        id: 1,
        name: 'Simon Joe',
        star: 5,
        date: '2 weeks ago',
        comment: 'Quick response, very competitive price. Driving has been so far so good with the new Lanvigator, Performax all season tires. Compared to previous GT Radial Champiro VP1 tires which have been worn down, the ride with new tires is both more comfortable and quieter. Hopefully I will remember to update as time goes regarding the performance and longevity of these tires.',
        image: '/images/footer-facebook.webp',
        link: config.google_review
    },
    {
        id: 2,
        name: 'Yevhen Hubanov',
        star: 5,
        date: '2 weeks ago',
        comment: 'I would like to recommend this wheel shop. Excellent staff work here. The guys are true professionals. They helped me with the choice of tires, told me what is the difference between them and what is the best tire. Thanks a lot for the great service and help. I know for sure that the following tires I bought in this place.',
        image: '/images/footer-instagram.webp',
        link: config.google_review
    },
    {
        id: 3,
        name: 'Mapolenka S',
        star: 5,
        date: '2 months ago',
        comment: 'After purchasing a set of tires, I wanted to exchange them for bigger ones and they exchange them for me. The staff here is very helpful and nice. The tires are at a very competitive price, which I already recomended to my friends. I would definitely come back for the great service and products!',
        image: '/images/footer-twitter.webp',
        link: config.google_review
    }
]

export default function CommentSection() {
    return (
        <div className='comment-wrap'>
            <h3>Customer reviews from Google</h3>
            <a href={config.google_review} alt={'write a review'} target='_blank' rel="noreferrer">Write a review</a>
            <div className='comment-section'>
                {comments.map(item => (
                    <div className='comment-item' key={item.id}>
                        {/* <img width={60} src={item.image} alt={item.name} /> */}
                        <h3>{item.name}</h3>
                        <p><span style={{ color: '#fbbc04' }}>{Array(item.star).fill().map((_, i) => <AiFillStar key={i} />)}</span> {item.date}</p>
                        <p>{item.comment}</p>
                        <a href={item.link} alt={item.name} target='_blank' rel="noreferrer">Read More</a>
                    </div>
                ))}
            </div>
        </div>
    )
}
