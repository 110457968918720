import React, { useState } from 'react'
// import { AiTwotoneShop, AiOutlineRight, AiFillCrown } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { setProductsFilter, setProductsFilterSize } from '@reducers/contentReducer'
import { useNavigate } from 'react-router-dom'
import SearchData from '@data/SearchData'

export default function FindTireBySize() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({
        width: 'TIRE WIDTH',
        profile: 'ASPECT RATIO',
        rim: 'RIM SIZE',
        type: 'ALL TIRES',
        // brand: 'ALL BRANDS'
    })

    // get Data
    const getData = (key) => {
        return formData.hasOwnProperty(key) ? formData[key] : ''
    }

    // Set data
    const setData = (key, value) => {
        return setFormData({ ...formData, [key]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log('formData', formData)
        let width = getData('width')
        let profile = getData('profile')
        let rim = getData('rim')
        // let title = ''
        // if (width !== 'TIRE WIDTH' && profile !== 'ASPECT RATIO' && rim !== 'RIM SIZE') {
        //     title = `${width}/${profile}R${rim}`
        // }
        dispatch(setProductsFilterSize({
            width: width !== 'TIRE WIDTH' ? Number(width) : 0,
            profile: profile !== 'ASPECT RATIO' ? Number(profile) : 0,
            rim: rim !== 'RIM SIZE' ? Number(rim) : 0,
        }))
        let type = getData('type')
        // let brand = getData('brand')
        dispatch(setProductsFilter({
            // title: title,
            types: type && type !== 'ALL TIRES' ? [Number(type)] : [],
            brands: []
        }))
        navigate('/tires/list')
    }

    const productsTypeList = useSelector((state) => state.content.productsTypeList)

    return (

        <form className='tire-search' onSubmit={handleSubmit}>
            <div className='tire-search-content'>
                <h3><span>1</span>Enter Tire Size</h3>
                <div className='tire-search-list'>
                    <div className='tire-search-list-item'>
                        <h4>WIDTH</h4>
                        <select placeholder='TIRE WIDTH' value={getData('width')} onChange={(e) => setData('width', e.target.value)}>
                            {SearchData.width.map((item, index) => {
                                return <option key={index} value={item}>{item}</option>
                            })}
                        </select>
                    </div>
                    <div className='tire-search-list-item'>
                        <h4>PROFILE</h4>
                        <select placeholder='ASPECT RATIO' value={getData('profile')} onChange={(e) => setData('profile', e.target.value)}>
                            {SearchData.profile.map((item, index) => {
                                return <option key={index} value={item}>{item}</option>
                            })}
                        </select>
                    </div>
                    <div className='tire-search-list-item tire-search-list-desktop'>
                        <h4>RIM</h4>
                        <select placeholder='RIM SIZE' value={getData('rim')} onChange={(e) => setData('rim', e.target.value)}>
                            {SearchData.rim.map((item, index) => {
                                return <option key={index} value={item}>{item}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div className='tire-search-list tire-search-list-mobile'>
                    <div className='tire-search-list-item'>
                        <h4>RIM</h4>
                        <select placeholder='RIM SIZE' value={getData('rim')} onChange={(e) => setData('rim', e.target.value)}>
                            {SearchData.rim.map((item, index) => {
                                return <option key={index} value={item}>{item}</option>
                            })}
                        </select>
                    </div>
                </div>
            </div>
            <div className='tire-search-content'>
                <h3><span>2</span>Select Tire Type or Brand</h3>
                <div className='tire-search-list'>
                    <div className='tire-search-list-item'>
                        <h4>TYPE</h4>
                        <select placeholder='ALL TIRES' value={getData('type')} onChange={(e) => setData('type', e.target.value)}>
                            <option value=''>ALL TIRES</option>
                            {productsTypeList?.map((item, index) => {
                                return <option key={index} value={item.id}>{item.title}</option>
                            })}
                        </select>
                    </div>
                </div>
            </div>
            <div className='tire-search-content'>
                <h3><span>3</span>Search</h3>
                <button type='submit' className='common-button'>FIND MY TIRES</button>
            </div>
        </form>

    )
}
