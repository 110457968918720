import './ProductItem.css'
import React from 'react'
import { Link } from 'react-router-dom'
import { config } from '@config'
import { useSelector } from 'react-redux'
import { ProductStatus } from './ProductView'
import ProductIcon3PMS from './ProductIcon3PMS'
import ProductNote from './ProductNote'

export default function ProductItem({
    product,
    onClick,
    link
}) {

    const user = useSelector((state) => state.user.userinfo)
    // const localtionId = user ? (product?.location || 0) : 0
    const localtionId = product?.location || 0
    return (
        <>
            <div className="product-item">
                <div className="item-img">
                    <ProductIcon3PMS product={product} />
                    {product.pictures && (
                        <Link to={link} onClick={onClick}>
                            <img className='item-img-product' src={product.pictures.split(',')[0]} alt={product.title} />
                        </Link>
                    )}
                    {!product.pictures && product.pattern_picture && (
                        <Link to={link} onClick={onClick}>
                            <img className='item-img-product' src={product.pattern_picture} alt={product.title} />
                        </Link>
                    )}
                </div>
                <div className="product-item-content">
                    <h3><Link to={link} onClick={onClick}>{product.title}</Link></h3>
                    <p>{product.description}</p>
                    {product.status_type > 0 &&
                        // <span className={currentRow.status_type === 1 ? 'status_on_sale' : 'status_on_clear'}>{product.status_type === 1 ? 'On Sale' : 'On Clearance'}</span>
                        <span className={ProductStatus[product.status_type - 1].className}>{ProductStatus[product.status_type - 1].name}</span>
                    }
                    <div className='product-price'>
                        <h4>(Tire Price) Retail: ${Number(product.retail_price)}</h4>
                        {user && user.is_view && (
                            <>
                                <h4 className='product-price-partner'>Partner: ${Number(product.price)}</h4>
                                {user.is_vip && Number(product.vip_price) > 0 ?
                                    (<h4 className='product-price-vip'>VIP: ${Number(product.vip_price)}</h4>
                                    ) : null}
                            </>
                        )}
                    </div>
                    {user && user.is_view && (
                        <p>
                            <strong>For shop:</strong><br />
                            Price does not include: tire recycling fee <font color='red'>($4 per tire)</font>; delivery fee <font color='red'>(varies by distance)</font>; credit card fee <font color='red'>(2%)</font>
                            <br />
                            Certain sizes cannot break set of four.
                        </p>
                    )}
                    {user && product.status_visible !== 1 && (
                        <h4>Quantity: {product.count >= 16 ? '16+' : product.count}</h4>
                    )}
                    <h4>Location: {config.location[localtionId]}</h4>
                    {product?.tread_depth && (
                        <h4>Tread Depth: {product.tread_depth} <span style={{
                            fontSize: '12px',
                            fontWeight: 400
                        }}>(32nd inch)</span></h4>
                    )}
                    {product.tags && (
                        <h4>Tags: {product.tags}</h4>
                    )}
                    {/* <ProductNote /> */}
                    <div className='product-item-btns'>
                        {/* <button className="common-button">Add to Cart</button> */}
                        <Link to={link} onClick={onClick} className="view-detail">
                            <button className="common-button">View Details</button>
                        </Link>
                    </div>
                </div>
            </div>

        </>
    )
}
